import React from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/PageHeader';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Paragraph } from 'components/Typography';
import styles from './index.module.less';
import image1 from 'images/project-4a.png';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="Home Now Safe & Secure"
      meta={[
        {
          name: 'description',
          content:
            'Leaks, electric shocks and roof in bad state of repair were just some of the problems affecting this family home.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Home Now Safe &amp; Secure"
      intro="Leaks, electric shocks and roof in bad state of repair were just some of the problems affecting this family home."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            A solo mum (to two teenagers and foster children) was in a state of utter desperation
            about the urgent repairs needed to her home. To add to the problem, she was struggling
            to cope with the demands of family life in a space that just wasn&rsquo;t big enough for
            their needs.
          </Paragraph>
          <Paragraph>
            The roof was in such a rundown state that water was getting into the wiring and causing
            electric shocks. The electrician had to disconnect power to half the house until the
            roof was repaired. Along with repairing the roof, the toilet floor and other wet areas
            were revamped. Plus Ryhaven delivered a brand new cabin and caravan on site to relieve
            overcrowding.
          </Paragraph>
          <Paragraph>
            As part of the process, this homeowner agreed to meet with a budget adviser to work
            towards managing her financial affairs.
          </Paragraph>
          <Paragraph>
            These home improvements have made a significant difference to the health and wellbeing
            of this family. They are delighted to now live in a safe and warm home.
          </Paragraph>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
